<section id="topbar">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-8 col-sm-8 hidden-xs">
                <div class="contact-info pull-left">
                    <ul>
                        <li><i class="fa fa-phone"></i>  0532 606 96 17 - 0326 413 68 10 </li>
                        <li><i class="fa fa-clock-o"></i> Pazartesi - Pazar: 09:00 - 20:00</li>
                    </ul>
                </div> <!-- /.contact-info -->
                
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 hidden-xs">
                <a href="appointment.html" class="theme-btn btn-md quote-btn">Teklif Al</a>
            </div>
        </div>
    </div>
</section>
