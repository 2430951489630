import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TopmenuComponent } from './topmenu/topmenu.component';
import { FooterComponent } from './footer/footer.component';
import { TopbarComponent } from './topbar/topbar.component';
import { HAKKIMIZDAComponent } from './hakkimizda/hakkimizda.component';
import { AnasayfaComponent } from './anasayfa/anasayfa.component';
import { HizmetlerimizComponent } from './hizmetlerimiz/hizmetlerimiz.component';

@NgModule({
  declarations: [
    AppComponent,
    TopmenuComponent,
    FooterComponent,
    TopbarComponent,
    HAKKIMIZDAComponent,
    AnasayfaComponent,
    HizmetlerimizComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
