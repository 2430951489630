import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HAKKIMIZDAComponent } from './hakkimizda/hakkimizda.component';
import { AppComponent } from './app.component';
import { AnasayfaComponent } from './anasayfa/anasayfa.component';
import { HizmetlerimizComponent } from './hizmetlerimiz/hizmetlerimiz.component';





const routes: Routes = [
  { path: '', component: AnasayfaComponent },
  { path: 'Hakkimizda', component: HAKKIMIZDAComponent },
  { path: 'Hizmetlerimiz', component: HizmetlerimizComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
