<section class="page-title inner-baner">
    <div class="container">
        <h2>HAKKIMIZDA</h2>
        <ul class="bread-crumb clearfix">
            <li><i class="fa fa-home"></i> <a href="/">Ana Sayfa</a></li>
            <li class="active">Hakkımızda</li>
        </ul>
    </div>
</section>
<!--End Page Title-->


<section id="company-about" class="sec-pad">
    <div class="container">
        <div class="row ">
            <div class="col-md-6 col-sm-12">
                <h1 class="text-uppercase">
                    <b>Uzmanlık Alanlarımız 
                        <span class="red-color">Güneş Enerji Sistemleri-Demir Doğrama-Sıhhi Tesisat</span>
                    </b>
                </h1>
                <p>
                    Özgür Isı Olarak 1994 yılında Hatay/Reyhanlı'da başladığımız meslek hayatımıza her geçen gün kendimizi ve işlerimizi geliştirerek Reyhanlı, çevre il ve ilçelere hizmet vererek yolumuza devam etmekteyiz. Her zaman müşteri memnuniyeti odaklı çalışmaktayız. Zamanında ve kaliteden ödün vermeden işlerinizi itina ile yapma çabasındayız.            </p>
                <div class="row">
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <div class="about-link">
                            <div class="area-widget">
                                <ul>
                                    <li><a href="#">Güneş Enerji Sistemleri</a></li>
                                    <li><a href="#">Sıhhi Tesisat</a></li>
                                    <li><a href="#">Demir Doğrama</a></li>
                                    <li><a href="#">Montaj & Bakım</a></li>
                                    <li><a href="#">Demir Kapı</a></li>
                                    <li><a href="#">Demir Korkuluk</a></li>
                                </ul>
                            </div>
                            <!-- /area-widget -->
                        </div><!-- /about-link -->
                    </div>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <div class="about-link">
                            <div class="area-widget">
                                <ul>
                                    <li><a href="#">Merdiven</a></li>
                                    <li><a href="#">Yangın Merdiveni</a></li>
                                    <li><a href="#">Çatı İşleri</a></li>
                                    <li><a href="#">Tamirat & Tadilat</a></li>
                                    <li><a href="#">Kaynak & Kesim</a></li>
                                    <li><a href="#">Kamyonet Kasası</a></li>
                                </ul>
                            </div><!-- /area-widget -->
                        </div><!-- /about-link -->
                    </div>
                </div>
            </div><!-- /cols -->
            <div class="col-md-6 col-sm-12">
                <div class="video-bx">
                    <div class="video-image-box">
                        <figure class="image"><iframe width="560" height="315" src="https://www.youtube.com/embed/W-WzwHNBMQY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </figure>
                    </div>
                </div><!-- /video-bx -->
            </div><!-- /col -->
        </div>
        <br>
            <hr>
            <div class="row">
                <div class="col-md-12">
                    <h1 class="text-uppercase">
                        <b>
                            <span class="red-color">HAKKIMIZDA</span>
                        </b>
                    </h1>
                    <p>
                         Özgür Isı Olarak 1994 yılında başladığımız meslek hayatımıza her geçen gün kendimizi ve işlerimizi geliştirerek yolumuza devam etmekteyiz. Her zaman müşteri memnuniyeti odaklı çalışmaktayız. Zamanında ve kaliteden ödün vermeden işlerinizi itina ile yapma çabasındayız. Hatay/Reyhanlı bölgesi başta olmak üzere çevre ilçe e illerde siz değerli müşterilerimize, Güneş Enerji Sistemleri imalat,bakım ve montajı, Demir Doğrama, Çatı işlemleri, Demir kapı,sundurma,korkuluk imalatı hizmetlerini sağlamaktayız.                       </p>
                </div>
                <div class="col-md-12">
                    <h1 class="text-uppercase">
                        <b>
                            <span class="red-color">VİZYONUMUZ</span>
                        </b>
                    </h1>
                    <p>
                        Profesyonel olarak sürdürdüğümüz sıhhi tesisat ve demir doğrama mesleğini yenilikleri sürekli takip ederek taleplere teknolojiyide kullanarak kalıcı çözümler üretebilmek ve  bunun yanı sıra müşteri memnuniyetini üst düzeyde tutarak dürüst,güvenilir mesleğinde başarılı esnaf olarak yolunda ilerlemek. 
                    </p>
               </div>
               <div class="col-md-12">
                <h1 class="text-uppercase">
                    <b>
                        <span class="red-color">MİSYONUMUZ</span>
                    </b>
                </h1>
                <ul>
                    <li>İş güvenliğini sağmalak ,iş sağlığına karşı riskleri güvence altına almak.</li>
                    <li>Çevreye ve insanlara saygılı hizmet anlayışı ile çalışmak.</li>
                        <li>Yeniliklerden her daim haberdar olup müşterilerimize sunmak.</li>
                        <li>Sektörde öncü olup mutlu müşteri portföyümüzü genişletmek.</li>
                </ul>
           </div>
            </div>
    </div>
</section>
<!-- /end -->

<section id="faq-section" class="sec-pad">
    <div  class="container">
        <div class="section-title text-center wd-50 mr-btm">
            <h1>Sıkça Sorulan<span class="red-color"> Sorular</span></h1>
            <p>
                Bu, hizmetimizi ve müşteri memnuniyetini geliştirmemize yardımcı olur
            </p>
        </div>
        <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12">                         
                <div class="panel-group" id="accordion">
                    <div class="panel panel-default">
                        <div class="panel-heading">
                            <h4 class="panel-title">
                                <a class="accordion-toggle" data-toggle="collapse" data-parent="#accordion" href="#collapseOne">
                                    <b>Hangi bölgelerde hizmet veriyorsunuz?</b>
                                </a>
                            </h4>
                        </div>
                        <div id="collapseOne" class="panel-collapse collapse in">
                            <div class="panel-body">
                                <p>
İşletmemiz Hatay/Reyhanlı'da bulunmasına rağmen, Hatay'ın diğer ilçelerine ve çevre illere hizmet vermekteyiz.
                                </p>

                            </div>
                        </div>
                    </div>
                    
                </div><!-- end panel group -->
            </div><!-- end col -->
        </div>
    </div>
</section>
<section id="news-letter">
    <div class="container">
        <div class="row">
            <div class="col-md-1 col-sm-1 col-xs-12">
                <div class="email-icon">
                    <i class="fa fa-envelope-square" aria-hidden="true"></i>
                </div>
            </div>
            <div class="col-md-5 col-sm-5 col-xs-12">
                <p>
                    Sektörel bilgilendirme ve haberlerimiz ile ilgili bilgilendirilmek isterseniz e-mail adresinizi yazarak abone olabilirsiniz.
                </p>
            </div>
            <div class="col-md-6 col-sm-6 col-xs-12">
                <div class="subscribe-form">
                    <input name="email" type="text" placeholder="Mail adresi">
                    <button class="subscribe-button" type="submit">kaydet</button>
                </div>
            </div>
        </div>
    </div>
    </section>
