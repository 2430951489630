<footer>
    <div class="overlay sec-pad">
        <div class="container">
            <div class="row">
                <div class="col-md-3 col-sm-6">
                    <div class="info-bx">
                        <i class="fa fa-clock-o" aria-hidden="true"></i>
                        <p>Pazartesi - Pazar<span>                         09:00 - 20:00
                        </span></p>
                    </div>
                </div>
                <!-- End col -->
                <div class="col-md-3 col-sm-6">
                    <div class="info-bx">
                        <i class="fa fa-map-marker" aria-hidden="true"></i>
                        <p>Fidanlık, 38. Sokak, No:46, 31500 Reyhanlı/HATAY
                        </p>
                    </div>
                </div>
                <!-- End col -->
                <div class="col-md-3 col-sm-6">
                    <div class="info-bx">
                        <i class="fa fa-envelope" aria-hidden="true"></i>
                        <p>E-POSTA:<span><a href="mailto:info@ozgurisihatay.com">info@ozgurisihatay.com</a></span></p>
                        <p><span><a href="mailto:m-surekci@hotmail.com">m-surekci@hotmail.com</a></span></p>
                    </div>
                </div>
                <!-- End col -->
                <div class="col-md-3 col-sm-6">
                    <div class="info-bx">
                        <i class="fa fa-phone" aria-hidden="true"></i>
                        <p>TELEFON:<span>0 532 606 96 17</span></p>
                        <p><span>0 326 413 68 10</span></p>
                    </div>
                </div>
                <!-- End col -->
            </div>
            <div class="row mr-top">
                <div class="col-md-4 col-sm-12">
                    <div class="widget">
                        <h3>Özgür Isı</h3>
                        <div class="about-text">
                            <p>
                            Fidanlık, 38. Sokak, No:46, 31500 Reyhanlı/HATAY
                            </p>
                        </div>
                        <div class="social-links-footer">
                            <a href="https://www.facebook.com/surekci" target="_blank"> <i class="fa fa-facebook-f"></i></a>
                            <a href="https://www.instagram.com/msurekci/" target="_blank"><i class="fa fa-instagram"></i></a>
                            <a href="#"><i class="fa fa-google"></i></a>
                            <a href="#"> <i class="fa fa-linkedin"></i></a>
                        </div><!-- social-link -->
                    </div>
                </div>
                <div class="col-md-4 col-sm-3 col-xs-6">
                    <div class="widget">
                        <h3>Site Haritası</h3>
                        <div class="quick-links">
                            <ul>
                              <li><a href="#">Hakkımızda</a></li>
                              <li><a href="#">Hizmetlerimiz</a></li>
                              <li><a href="#">Blog</a></li>
                              <li><a href="#">İleişim</a></li>
                          </ul>
                      </div>
                    </div>
                </div><!-- /col -->
               
                <div class="col-md-4 col-sm-6">
                    <div class="plb-man">
                        <img src="assets/images/resource/ftr-pman.png" alt="img">
                        <div class="phn-num">
                            532 606 96 17
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

        <div class="footer-btm">
            <div class="container">
                <div class="row">
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <div class="create-by">
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <div class="copywrite">
                            <p>© {{anio}} Bütün hakları saklıdır.</p> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End footer-btm --> 
</footer>

