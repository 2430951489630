<section class="page-title inner-baner">
    <div class="container">
        <h2>Hizmetlerimiz</h2>
        <ul class="bread-crumb clearfix">
            <li><a href="index.html">Ana Sayfa</a></li>
            <li class="active">Hizmetlerimiz</li>
        </ul>
    </div>
</section>
<!--End Page Title-->

<section id="service-page-second" >
    <div class="service-container">
        <div class="container">
            <div class="row">
                <div class="col-md-6 pull-right">
                    <div class="service-img-box shdow brdr">
                        <img src="assets/images/service/1.jpg" alt="">
                    </div>
                </div>
                <div class="col-md-6 col-xs-12">
                    <div class="service-content-box">
                        <h1>Güneş Enerji Sistemleri</h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End service-container-->
    <div class="service-container bg-gray">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <div class="service-img-box brdr shdow">
                        <img src="images/service/2.jpg" alt="">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="service-content-box">
                        <h1>Demir Doğrama</h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End service-container-->

    <div class="service-container bg-blue">
        <div class="container">
            <div class="row">
                <div class="col-md-6 pull-right">
                    <div class="service-img-box brdr">
                        <img src="images/service/3.jpg" alt="">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="service-content-box">
                        <h1 class="color-wht">Sıhhi Tesisat</h1>
                        <p class="color-wht">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p> 
                        <p class="color-wht">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation laborum.</p> 
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End service-container-->
    <div class="service-container">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <div class="service-img-box shdow brdr">
                        <img src="images/service/4.jpg" alt="">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="service-content-box">
                        <h1>ÇATI & SUNDURMA</h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End service-container-->
    <div class="service-container bg-red">
        <div class="container">
            <div class="row">
                <div class="col-md-6 pull-right">
                    <div class="service-img-box brdr">
                        <img src="images/service/5.jpg" alt="">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="service-content-box">
                        <h1 class="color-wht">KAPI & KORKULUK</h1>
                        <p class="color-wht">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p> 
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End service-container-->
   </section><!-- / --> 


    <!-- Start Faq Section Section -->
    <section id="faq-section" class="sec-pad">
        <div  class="container">
            <div class="section-title text-center wd-50 mr-btm">
                <h1>Sıkça Sorulan<span class="red-color"> Sorular</span></h1>
                <p>
                    Bu, hizmetimizi ve müşteri memnuniyetini geliştirmemize yardımcı olur
                </p>
            </div>
            <div class="row">
                <div class="col-md-12 col-sm-12 col-xs-12">                         
                    <div class="panel-group" id="accordion">
                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <h4 class="panel-title">
                                    <a class="accordion-toggle" data-toggle="collapse" data-parent="#accordion" href="#collapseOne">
                                        <b>Hangi bölgelerde hizmet veriyorsunuz?</b>
                                    </a>
                                </h4>
                            </div>
                            <div id="collapseOne" class="panel-collapse collapse in">
                                <div class="panel-body">
                                    <p>
    İşletmemiz Hatay/Reyhanlı'da bulunmasına rağmen, Hatay'ın diğer ilçelerine ve çevre illere hizmet vermekteyiz.
                                    </p>
    
                                </div>
                            </div>
                        </div>
                        
                    </div><!-- end panel group -->
                </div><!-- end col -->
            </div>
        </div>
    </section>
<!-- End Faq Section Section -->
<section id="news-letter">
    <div class="container">
        <div class="row">
            <div class="col-md-1 col-sm-1 col-xs-12">
                <div class="email-icon">
                    <i class="fa fa-envelope-square" aria-hidden="true"></i>
                </div>
            </div>
            <div class="col-md-5 col-sm-5 col-xs-12">
                <p>
                    Sektörel bilgilendirme ve haberlerimiz ile ilgili bilgilendirilmek isterseniz e-mail adresinizi yazarak abone olabilirsiniz.
                </p>
            </div>
            <div class="col-md-6 col-sm-6 col-xs-12">
                <div class="subscribe-form">
                    <input name="email" type="text" placeholder="Mail adresi">
                    <button class="subscribe-button" type="submit">kaydet</button>
                </div>
            </div>
        </div>
    </div>
    </section>
