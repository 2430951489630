<div id="bootstrap-touch-slider" class="carousel bs-slider fade  control-round indicators-line" data-ride="carousel" data-pause="hover" data-interval="5000" >

    <!-- Indicators -->
    <ol class="carousel-indicators">
        <li data-target="#bootstrap-touch-slider" data-slide-to="0" class="active"></li>
        <li data-target="#bootstrap-touch-slider" data-slide-to="1"></li>
        <li data-target="#bootstrap-touch-slider" data-slide-to="2"></li>     
    </ol>

    <!-- Wrapper For Slides -->
    <div class="carousel-inner" role="listbox">

        <!-- Third Slide -->
        <div class="item active">

            <!-- Slide Background -->
            <img src="assets/images/main-slider/slider_1.jpg" alt="Güneş Enerji Sistemleri"  class="slide-image"/>
            <div class="bs-slider-overlay"></div>
            <div class="slide-text slide_style_left">
                <h1 data-animation="animated zoomInRight" >Güneş Enerji Sistemleri</h1>
                <ul class="list">
                    <li data-animation="animated fadeInLeft">
                        <i class="fa fa-check" aria-hidden="true"></i> Uzun ömürlü
                    </li>
                    <li data-animation="animated fadeInLeft">
                        <i class="fa fa-check" aria-hidden="true"></i> Verimli
                    </li>
                    <li data-animation="animated fadeInLeft">
                        <i class="fa fa-check" aria-hidden="true"></i> Ekonomik Projeler
                    </li>
                </ul>
                <a href="#" target="_blank" class="btn btn-default" data-animation="animated fadeInUp">Daha fazla bilgi</a>
            </div>
           
        </div>        
        <!-- End of Slide -->
       
        <!-- Second Slide -->
        <div class="item">

            <!-- Slide Background -->
            <img src="assets/images/main-slider/slider_3.jpg" alt="Sıhhi Tesisat"  class="slide-image"/>
            <div class="bs-slider-overlay"></div>
            <!-- Slide Text Layer -->
            <div class="slide-text slide_style_left">
                <h1 data-animation="animated zoomInRight" >Sıhhi Tesisat</h1>
                <ul class="list">
                    <li data-animation="animated fadeInLeft">
                        <i class="fa fa-check" aria-hidden="true"></i> Hızlı
                    </li>
                    <li data-animation="animated fadeInLeft">
                        <i class="fa fa-check" aria-hidden="true"></i> Temiz
                    </li>
                    <li data-animation="animated fadeInLeft">
                        <i class="fa fa-check" aria-hidden="true"></i> Kalıcı Çözümler
                    </li>
                </ul>
                <a href="#" target="_blank" class="btn btn-default" data-animation="animated fadeInUp">Daha fazla bilgi</a>
            </div>
        </div>
        
        <!-- End of Slide -->

        <!-- Third Slide -->
        <div class="item">

            <!-- Slide Background -->
            <img src="assets/images/main-slider/slider_2.jpg" alt="Demir doğrama"  class="slide-image"/>
            <div class="bs-slider-overlay"></div>
            <div class="slide-text slide_style_left">
                <h1 data-animation="animated zoomInRight" >Demir doğrama</h1>
                <ul class="list">
                    <li data-animation="animated fadeInLeft">
                        <i class="fa fa-check" aria-hidden="true"></i> Kaliteli
                    </li>
                    <li data-animation="animated fadeInLeft">
                        <i class="fa fa-check" aria-hidden="true"></i> Sağlam
                    </li>
                    <li data-animation="animated fadeInLeft">
                        <i class="fa fa-check" aria-hidden="true"></i> Kullanışlı İmalat
                    </li>
                </ul>
                <a href="#" target="_blank" class="btn btn-default" data-animation="animated fadeInUp">Daha fazla bilgi</a>
            </div>
                   
        </div>
        <!-- End of Slide -->
        

    </div><!-- End of Wrapper For Slides -->

    <!-- Left Control -->
    <a class="left carousel-control" href="#bootstrap-touch-slider" role="button" data-slide="prev">
        <span class="fa fa-angle-left" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
    </a>

    <!-- Right Control -->
    <a class="right carousel-control" href="#bootstrap-touch-slider" role="button" data-slide="next">
        <span class="fa fa-angle-right" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
    </a>

</div> 
 
<!--End Main Slider-->

<!--End Main Slider-->

<section id="promo">
<div class="container">
    <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12 no-padding">
        <div class="left-sec">
            <div class="pm-man">
                <img src="assets/images/resource/pm-man.png" alt="">
            </div>
            <div class="promo-title">
                <h4>Kaliteli çözümler için bizi arayın.</h4> 
            </div>
            
        </div>
        
    </div>
    <div class="col-lg-4 col-md-4 col-sm-3 col-xs-12 no-pad hidden-sm">
        <div class="right-sec">
            <h5 class="heading">
                <b>İLETİŞİM HATTI</b>
            </h5>
            <div class="phn-info">
            <div class="icon hidden-xs">
                <img src="assets/images/icons/phn.png" alt="">
            </div>
            <div class="phn-num">
                <h2>0 532 606 96 17</h2>
                <h2>0 326 413 68 10</h2> 
            </div>    
                
            </div>
        </div>
    </div>
</div>
</section>
<!-- /end -->

<section id="services" class="sec-pad">
<div class="container">
    <div class="row">
        <div class="col-md-3 col-sm-3">
            <div class="single-service-col">
                <div class="service-img-holder">
                    <img  class="responsive" src="assets/images/kaynak/Gunesenerjisi.jpg" alt="Güneş enerjisi">
                    <div class="icon-circle">
                        <img src="assets/images/icons/ge.png" alt="">
                    </div>
                </div>
                <div class="ser-nme hvr-bounce-to-top">
                    <a href="#"> Güneş Enerjisi</a>
                </div>
            </div><!-- /single-service-col -->
        </div><!-- /col -->
        <div class="col-md-3 col-sm-3">
            <div class="single-service-col">
                <div class="service-img-holder">
                    <img src="assets/images/kaynak/Korkuluk.jpg" alt="Korkuluk">
                    <div class="icon-circle">
                        <img src="assets/images/icons/korkuluk.png" alt="Korkuluk">
                    </div>
                </div>
                <div class="ser-nme hvr-bounce-to-top">
                    <a href="#">Kapı & Korkuluk</a>
                </div>
            </div><!-- /single-service-col -->
        </div><!-- /col -->
        <div class="col-md-3 col-sm-3">
            <div class="single-service-col">
                <div class="service-img-holder">
                    <img src="assets/images/kaynak/Cati.jpg" alt="Çatı Sundurma">
                    <div class="icon-circle">
                        <img src="assets/images/icons/Cati.png" alt="Çatı Sundurma">
                    </div>
                </div>
                <div class="ser-nme hvr-bounce-to-top">
                    <a href="#">Çatı & Sundurma</a>
                </div>
            </div><!-- /single-service-col -->
        </div><!-- /col -->
        <div class="col-md-3 col-sm-3">
            <div class="single-service-col">
                <div class="service-img-holder">
                    <img src="assets/images/kaynak/SuTesisati.jpg" alt="Su tesisatı">
                    <div class="icon-circle">
                        <img src="assets/images/icons/tesisat.png" alt="Su tesisatı">
                    </div>
                </div>
                <div class="ser-nme hvr-bounce-to-top">
                    <a href="#">Su Tesisatı</a>
                </div>
            </div><!-- /single-service-col -->
        </div><!-- /col -->
    </div><!-- /row -->
</div>
</section>
<!-- / -->
<section id="about" class="sec-pad sec-pad-top-none">
<div class="container">
    <div class="row ">
        <div class="col-md-6 col-sm-12">
            <h1 class="text-uppercase">
                <b>Uzmanlık Alanlarımız 
                    <span class="red-color">Güneş Enerji Sistemleri-Demir Doğrama-Sıhhi Tesisat</span>
                </b>
            </h1>
            <p>
                Özgür Isı Olarak 1994 yılında Hatay/Reyhanlı'da başladığımız meslek hayatımıza her geçen gün kendimizi ve işlerimizi geliştirerek Reyhanlı, çevre il ve ilçelere hizmet vererek yolumuza devam etmekteyiz. Her zaman müşteri memnuniyeti odaklı çalışmaktayız. Zamanında ve kaliteden ödün vermeden işlerinizi itina ile yapma çabasındayız.            </p>
            <div class="row">
                <div class="col-md-6 col-sm-6 col-xs-12">
                    <div class="about-link">
                        <div class="area-widget">
                            <ul>
                                <li><a href="#">Güneş Enerji Sistemleri</a></li>
                                <li><a href="#">Sıhhi Tesisat</a></li>
                                <li><a href="#">Demir Doğrama</a></li>
                                <li><a href="#">Montaj & Bakım</a></li>
                                <li><a href="#">Demir Kapı</a></li>
                                <li><a href="#">Demir Korkuluk</a></li>
                            </ul>
                        </div>
                        <!-- /area-widget -->
                    </div><!-- /about-link -->
                </div>
                <div class="col-md-6 col-sm-6 col-xs-12">
                    <div class="about-link">
                        <div class="area-widget">
                            <ul>
                                <li><a href="#">Merdiven</a></li>
                                <li><a href="#">Yangın Merdiveni</a></li>
                                <li><a href="#">Çatı İşleri</a></li>
                                <li><a href="#">Tamirat & Tadilat</a></li>
                                <li><a href="#">Kaynak & Kesim</a></li>
                                <li><a href="#">Kamyonet Kasası</a></li>
                            </ul>
                        </div><!-- /area-widget -->
                    </div><!-- /about-link -->
                </div>
            </div>
        </div><!-- /cols -->
        <div class="col-md-6 col-sm-12">
            <div class="video-bx">
                <div class="video-image-box">
                    <figure class="image"><iframe width="560" height="315" src="https://www.youtube.com/embed/W-WzwHNBMQY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </figure>
                </div>
            </div><!-- /video-bx -->
        </div><!-- /col -->
    </div>
</div>
</section>
<!-- /end -->
<!-- / -->
<section id="col-features-area" class="sec-pad sec-pad-btm-none">
    <div class="container">
        <div class="section-title text-center wd-50 mr-btm">
            <h1>İşletme <span class="red-color">Değerlermİz</span></h1>
            <p>
                kurucumuzun derin etik bağlılık ve sorumluluk değerlerine ile teknik uzmanlığın başarının sırrı olduğuna dair inancına her zaman sadık kalmıştır.            </p>
        </div>
        <div class="row">
            <div class="col-md-4 col-sm-12">
                <div class="row">
                 <div class="col-md-3 col-sm-12 pull-right-icon">
                    <div class="feature-icon-holder"><img src="assets/images/icons/support.png" alt=""></div>
                </div><!-- /col -->
                <div class="col-md-9 col-sm-12 pad-l">
                    <div class="col-feature-content pd-right">  
                        <h3 class="text-right">7/24 hizmet</h3>
                        <p>
                             24 saat kesintisiz hizmet alabileceğiniz işletmemize, her türlü sorununuza destek alabilmek için iletişim kanallarımızdan ulaşabilirsiniz.
                        </p>
                    </div> 
                </div> <!-- /col -->
            </div><!-- /row -->

            <div class="row">
             <div class="col-md-3 col-sm-12 pull-right-icon">
                <div class="feature-icon-holder"><img src="assets/images/icons/satisfaction.png" alt=""></div>
            </div><!-- /col -->
            <div class="col-md-9 col-sm-12 pad-l">
                <div class="col-feature-content pd-right">
                    <h3 class="text-right">Müşteri Memnuniyeti</h3>
                    <p>
                        İş süreçlerimizin performansını, sorumluluk ve inisiyatif sahibi tavrımızı ve güvenirliğimizi koruyarak, istenilen müşteri memnuniyetini sağlarız. 
                    </p>
                </div> 
            </div> <!-- /col -->
        </div><!-- /row -->

        <div class="row">
         <div class="col-md-3 col-sm-12 pull-right-icon">
            <div class="feature-icon-holder"><img src="assets/images/icons/licensed.png" alt=""></div>
        </div><!-- /col -->
        <div class="col-md-9 col-sm-12 pad-l">
            <div class="col-feature-content pd-right">
                <h3 class="text-right">Tecrübe</h3>
                <p>
                    Başarımızı sürdürmek ve hedeflerimize ulaşmak için, işini iyi bilen ve iyi yapan bir ekiple çalışırız. Bu nedenle çalışanlarımızın mesleki yeteneklerini geliştirmelerini teşvik eder ve onları uygun koşullar sunarak destekleriz.
                </p>
            </div> 
        </div> <!-- /col -->
    </div><!-- /row -->
</div>
<div class="col-md-4 col-sm-12">
    <div class="quote-bx">
        <div class="quote-text">
            <div class="quote-icon">
                <i class="fa fa-quote-right" aria-hidden="true"></i>
            </div>
            <p class="text-center">
                Her işe bağımsızlık, hesap verebilirlik ve toplumsal bilinçle yaklaşıyoruz. Değerlerimiz, davranış kurallarımızın ayrılmaz bir parçasını oluşturuyor. Bize yaptığımız her işte rehberlik ediyorlar: müşterilerimizin ihtiyaçlarını nasıl karşılayabiliriz, çalışanlarımızı nasıl geliştirebiliriz ve topluluklarımızda hangi rolü oynayabiliriz?             </p>
            <h4 class="text-center">Mehmet SÜREKÇİ</h4>
            <span class="sign center">
                <img src="images/resource/sign.png" alt="">
            </span>
        </div>
        <div class="plumber-van">
            <img src="images/resource/van.png" alt="">
        </div>
    </div>
</div>
<div class="col-md-4 col-sm-12 col-xs-12 mr-top">
    <div class="row">
     <div class="col-md-3 col-sm-12 col-xs-12 pull-left">
        <div class="feature-icon-holder"><img src="assets/images/icons/price.png" alt=""></div>
    </div><!-- /col -->
    <div class="col-md-9 col-sm-12 col-xs-12 pad-l">
        <div class="col-feature-content pd-left">
            <h3 class="text-left">Ön Fiyatlandırma</h3>
            <p>
                Talep ettiğiniz hizmet ile ilgili gereklilikleri önceden belirliyerek, sonradan sürpriz maliyetler ile karşılaşmadan maliyet bilgisine sahip olursunuz. 
            </p>
        </div> 
    </div> <!-- /col -->
</div><!-- /row -->

<div class="row">
    <div class="col-md-3 col-sm-12 col-xs-12 pull-left">
        <div class="feature-icon-holder"><img src="assets/images/icons/camera.png" alt=""></div>
    </div><!-- /col -->
    <div class="col-md-9 col-sm-12 col-xs-12 pad-l">
        <div class="col-feature-content pd-left">
            <h3 class="text-left">TeknolojİK EKİPMAN</h3>
            <p>
İşletmemize ait en son teknolojik cihazlar ile talep edilen hizmetlerin en kısa sürede ve en üst düzeyde kalite çizgisinde tamamlanmasını sağlamaktayız.  
            </p>
        </div> 
    </div> <!-- /col -->
</div><!-- /row -->

<div class="row">
   <div class="col-md-3 col-sm-12 col-xs-12 pull-left">
    <div class="feature-icon-holder"><img src="assets/images/icons/calendar-time.png" alt=""></div>
</div><!-- /col -->
<div class="col-md-9 col-sm-12 col-xs-12 pad-l">
    <div class="col-feature-content pd-left">
        <h3 class="text-left">Zamanında SerVİS</h3>
        <p>
            Siz değerli müşterilerimizin zamanının ne kadar değerli olduğunun farkında olarak  hizmet anlayışımızı hız, kalite, garanti ve satış sonrasında anında destek alabileceğiniz işinde tecrübeli personelimizle birebir iletişim desteği sağlamaktayız.
        </p>
    </div> 
</div> <!-- /col -->
</div><!-- /row -->
</div>
        </div>
    </div>
</section><!--End why-choose -->

<!-- Start Faq Section Section -->
<section id="faq-section" class="sec-pad">
    <div  class="container">
        <div class="section-title text-center wd-50 mr-btm">
            <h1>Sıkça Sorulan<span class="red-color"> Sorular</span></h1>
            <p>
                Bu, hizmetimizi ve müşteri memnuniyetini geliştirmemize yardımcı olur
            </p>
        </div>
        <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12">                         
                <div class="panel-group" id="accordion">
                    <div class="panel panel-default">
                        <div class="panel-heading">
                            <h4 class="panel-title">
                                <a class="accordion-toggle" data-toggle="collapse" data-parent="#accordion" href="#collapseOne">
                                    <b>Hangi bölgelerde hizmet veriyorsunuz?</b>
                                </a>
                            </h4>
                        </div>
                        <div id="collapseOne" class="panel-collapse collapse in">
                            <div class="panel-body">
                                <p>
İşletmemiz Hatay/Reyhanlı'da bulunmasına rağmen, Hatay'ın diğer ilçelerine ve çevre illere hizmet vermekteyiz.
                                </p>

                            </div>
                        </div>
                    </div>
                    
                </div><!-- end panel group -->
            </div><!-- end col -->
        </div>
    </div>
</section>
<!-- End Faq Section Section -->

<!-- / -->

<!-- start blog section-->
<section id="blog" class="sec-pad">
<div class="container">
    <div class="section-title text-center wd-50 mr-btm">
        <h1>En son <span class="red-color"> haberler</span></h1>
        <p>
İşletmemize ve sektörel gelişmeler ile ilgili haber & bilgilendirme yazılarımıza buradan ulaşabilirsiniz.
        </p>
    </div>

    <div class="row">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
            <div class="blog-main-holder">
                <div class="single-blog-post">
                    <div class="img-holder">
                        <img src="assets/images/blog/blogwebsitesi.jpg" alt="Özgür Isı Hatay">
                        <div class="date">
                            <h3>06<br><span>Haz</span></h3>
                        </div>
                    </div>
                    <div class="blog-content">
                        <h3><a href="#">WEB SİTEMİZ YAYINDA</a></h3>
                        <ul>
                            <li>Yazar : <span>Mehmet SÜREKÇİ</span> </li>
                        </ul>

                        <p>
                            Verdiğimiz hizmetleri ve mesleğimiz ile alakalı bilgilendirme...
                        </p>
                        <a class="readmore" href="#">Okumaya devam et</a>
                    </div>  
                </div><!-- single blog post -->
            </div><!-- blog-main-holder -->
        </div><!-- col -->

    </div>
</div>
</section><!-- End blog section-->

<section id="news-letter">
<div class="container">
    <div class="row">
        <div class="col-md-1 col-sm-1 col-xs-12">
            <div class="email-icon">
                <i class="fa fa-envelope-square" aria-hidden="true"></i>
            </div>
        </div>
        <div class="col-md-5 col-sm-5 col-xs-12">
            <p>
                Sektörel bilgilendirme ve haberlerimiz ile ilgili bilgilendirilmek isterseniz e-mail adresinizi yazarak abone olabilirsiniz.
            </p>
        </div>
        <div class="col-md-6 col-sm-6 col-xs-12">
            <div class="subscribe-form">
                <input name="email" type="text" placeholder="Mail adresi">
                <button class="subscribe-button" type="submit">kaydet</button>
            </div>
        </div>
    </div>
</div>
</section>
