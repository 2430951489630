<header>
    <!-- Start Navigation -->
    <nav class="navbar navbar-default navbar-sticky bootsnav">

        <div class="container">
            <!-- Start Header Navigation -->
            <div class="navbar-header">
                <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#navbar-menu">
                    <i class="fa fa-bars"></i>
                </button>
                <a class="navbar-brand" href="index.html"><img src="assets/images/icons/logo.ico" class="logo logo-scrolled" alt=""></a>
            </div>
            <!-- End Header Navigation -->

            <!-- Collect the nav links, forms, and other content for toggling -->
            <div class="collapse navbar-collapse" id="navbar-menu">
                <ul class="nav navbar-nav navbar-right" data-in="" data-out="">
                    <li><a href="/">Ana Sayfa</a></li>
                    <li><a href="/Hizmetlerimiz">Hizmetlerimiz</a></li> 
                    <li><a href="/Hakkimizda">Hakkımızda</a></li>                    
                    <li><a href="#">İletişim</a></li>
                    
                </ul>
            </div><!-- /.navbar-collapse -->
        </div>
    </nav>
    
    <!-- End Navigation -->
    <div class="clearfix"></div>
</header>
